<template>
  <v-card>
    <template v-if="loading">
      <v-card-text class="my-9">
        <v-row>
          <v-col class="text-center" cols="12">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-col>
          <v-col class="text-center" cols="12">Please wait...</v-col>
        </v-row>
      </v-card-text>
    </template>
    <template v-else>
      <template v-if="pauseSubscription">
        <v-card-title>
          <v-spacer></v-spacer>
          <div class="text-center semi-bold titleFont">
            How about pausing instead?
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="text-center bodyFont mt-4" cols="12">
              Pausing your subscription guarantees that you'll keep your team
              members, storage, releases, apps safe without being billed.
            </v-col>
            <v-col class="text-center" cols="12">
              <div class="bodyFont mb-2">
                How long would you like to pause your subscription?
              </div>
              <v-row justify="center">
                <v-col class="pb-0" cols="8">
                  <v-select
                    v-model="pauseMonth"
                    :items="pauseItems"
                    filled
                    dense
                    hide-details
                    item-text="text"
                    item-value="value"
                  ></v-select>
                  <p>One-time offer</p>
                </v-col>
                <v-col class="pt-4" cols="7">
                  <v-btn
                    :outlined="$vuetify.theme.dark"
                    depressed
                    :disabled="!pauseMonth"
                    block
                    @click="
                      initiateClaimOffer({
                        id: pauseDetail.id,
                        value: `${pauseMonth}`,
                      })
                    "
                    color="primary"
                    :loading="busy"
                    class="text-transform-none"
                    >Pause subscription</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>

            <v-alert class="mt-3" border="left" color="info" dense outlined>
              <template #default>
                <div class="font14">
                  This will only reschedule your next payment. Your team will
                  stay active and operate normally.
                </div>
              </template>
            </v-alert>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('close')"
            text
            color="primary"
            :disabled="busy"
            class="text-transform-none"
            >Close</v-btn
          >
          <v-btn
            @click="skipFromPause"
            text
            color="primary"
            :disabled="busy"
            class="text-transform-none"
            >Proceed to cancel</v-btn
          >
        </v-card-actions>
      </template>
      <template v-if="showDowngradeForm">
        <v-card-title>
          <v-spacer></v-spacer>
          <div class="semi-bold titleFont">Downgrade your plan?</div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row class="py-4">
            <v-col class="text-center text-h6" cols="12">
              <div class="text-center">
                Your current plan is
                <b>
                  {{ subscriptionInfo.name + " " }}
                  <span class="text-capitalize"
                    >({{ subscriptionInfo.recurring }})</span
                  >
                </b>
              </div>
              <div class="text-center mt-4 bodyFont">
                <div>
                  You can downgrade your team's plan to Starter (Monthly or
                  Annually), effective immediately.
                </div>
              </div>

              <v-alert
                class="mt-3 text-left"
                border="left"
                color="success"
                dense
                outlined
              >
                <template #default>
                  <div class="font14">
                    The unused portion of the current billing cycle is applied
                    as a credit for your following payments.
                  </div>
                </template>
              </v-alert>

              <v-alert
                class="mt-3 text-left"
                border="left"
                color="error"
                dense
                outlined
              >
                <template #default>
                  <div class="font14">
                    Please review the
                    <a href="https://testapp.io/pricing" target="_blank"
                      >plan changes</a
                    >
                    before you downgrade. Your team members, apps, releases, and
                    other features might be affected.
                  </div>
                </template>
              </v-alert>
            </v-col>
            <v-col class="pt-0 text-center" cols="12">
              <v-row justify="center">
                <v-col class="pb-0" cols="10">
                  <v-select
                    v-model="selectedPlanId"
                    item-value="id"
                    filled
                    dense
                    id="plan"
                    hide-details
                    persistent-hint
                    placeholder="Select a plan"
                    :items="plansList"
                  >
                    <template v-slot:selection="{ item }">
                      <div>
                        {{ item.name + " " }}
                        <span class="text-capitalize"
                          >({{ item.recurring }})</span
                        >
                        <span> for ${{ item.meta.display_price }}/month </span>
                        <v-chip
                          small
                          class="ml-3"
                          v-if="item.recurring === 'annually'"
                          >Get 30% discount (~4 months)</v-chip
                        >
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <div>
                        {{ item.name + " " }}
                        <span class="text-capitalize"
                          >({{ item.recurring }})
                        </span>
                        <span> for ${{ item.meta.display_price }}/month </span>
                        <v-chip
                          small
                          class="ml-3"
                          v-if="item.recurring === 'annually'"
                          >Get 30% discount (~4 months)</v-chip
                        >
                      </div>
                    </template>
                  </v-select>
                  <p>There's no undo for this action</p>
                </v-col>
                <v-col class="pt-4" cols="7">
                  <v-btn
                    color="primary"
                    class="text-transform-none"
                    block
                    :disabled="!selectedPlanId"
                    :outlined="$vuetify.theme.dark"
                    @click="
                      initiateClaimOffer({
                        id: downgradeDetails.id,
                        value: `${selectedPlanId}`,
                      })
                    "
                    :loading="busy"
                    depressed
                  >
                    Downgrade
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="pauseDetail"
            color="primary"
            text
            :disabled="busy"
            class="text-transform-none"
            @click="previousFromDowngrade"
          >
            <v-icon v-text="'mdi-chevron-left'" left></v-icon>
            Previous
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('close')"
            text
            :disabled="busy"
            color="primary"
            class="text-transform-none"
            >Close</v-btn
          >
          <v-btn
            text
            color="primary"
            :disabled="busy"
            class="text-transform-none"
            @click="skipFromDowngrade"
          >
            Proceed to cancel
          </v-btn>
        </v-card-actions>
      </template>
      <template v-if="offerMode">
        <v-card-title>
          <v-spacer></v-spacer>
          <div class="semi-bold titleFont">We totally get it.</div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="text-center bodyFont my-4" cols="12">
              We understand the need to be cost conscious right now and want to
              make TestApp.io affordable to you.
            </v-col>
            <v-col
              class="text-center blue-grey lighten-5 py-5 bodyFont"
              cols="12"
            >
              <div class="semi-bold">Claim your limited time offer.</div>
              <div class="my-3">
                {{ offerDetail.message }}
              </div>
              <v-btn
                depressed
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                :loading="busy"
                @click="initiateClaimOffer({ id: offerDetail.id })"
                color="primary"
              >
                Accept this offer
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="pauseDetail || downgradeDetails"
            @click="previousFromOffer"
            text
            color="primary"
            :disabled="busy"
            class="text-transform-none"
          >
            <v-icon v-text="'mdi-chevron-left'" left></v-icon>
            Previous</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('close')"
            text
            :disabled="busy"
            color="primary"
            class="text-transform-none"
            >Cancel</v-btn
          >
          <v-btn
            @click="skipToCancelModal"
            text
            :disabled="busy"
            color="primary"
            class="text-transform-none"
            >Decline offer</v-btn
          >
        </v-card-actions>
      </template>
      <template v-if="cancellationSubscriptionNote">
        <v-card-title class="font-weight-bold">
          Cancel subscription
        </v-card-title>
        <v-divider />
        <v-card-text class="message mt-4">
          Cancelling the subscription will be implemented
          <span v-if="subscriptionInfo.cancellation_effective_date">
            on
            <b
              >{{
                getFormattedDate(subscriptionInfo.cancellation_effective_date)
              }}.</b
            >
          </span>
          <span v-else>
            by the next billing cycle
            <b>{{ getFormattedDate(subscriptionInfo.next_bill_date) }}</b
            >.
          </span>
          <br />
          <br />
          The team will be downgraded to the free plan. Members might lose
          access to certain features when downgrading as well as archiving the
          over-the-limit releases.
          <br />
          <br />
          Once the cancellation implemented, there's no undo but until then you
          can still subscribe again to stop the cancellation order before the
          cancellation date.
          <br />

          <br />

          <v-alert
            class="mt-3"
            border="left"
            icon="info"
            dense
            color="primary"
            outlined
          >
            <template #default>
              <div class="font14 normal-text">
                If it is something that we can help with, please do
                <span
                  @click="onContactus"
                  class="pointer blue--text text-decoration-underline"
                  >contact us</span
                >
                or
                <span
                  @click="openSchedule"
                  class="pointer blue--text text-decoration-underline"
                >
                  schedule a call
                </span>
                and we'll gladly help you out!
              </div>
            </template>
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-3">
          <v-spacer />
          <v-btn
            text
            id="cancelDeclineBtn"
            @click="$emit('close')"
            class="text-transform-none"
            color="primary"
            >Close</v-btn
          >
          <v-btn
            id="cancelConfirmBtn"
            @click="openCancelReason"
            color="primary"
            :outlined="$vuetify.theme.dark"
            class="text-transform-none"
            >Got it, cancel it please</v-btn
          >
        </v-card-actions>
      </template>
      <cancel-plan-modal
        v-if="cancelReason"
        @close="$emit('close')"
        @success="$emit('cancel-confirm')"
      ></cancel-plan-modal>
    </template>
  </v-card>
</template>
<script>
import { formatBytes, get, isEmpty } from "@/core/services/helper.service";
import CancelPlanModal from "@/view/components/Team/CancelPlanModal";
import {
  INIT_CANCEL,
  CLAIM_OFFER,
  CHANGE_PLANS,
} from "@/store/team/team.module.js";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { GET_USER_ROLES } from "@/store/users/auth.module";
import { segmentEvents } from "@/consts/segmentEventConst";
export default {
  components: { CancelPlanModal },
  props: {
    subscriptionInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      formatBytes,
      get,
      isEmpty,
      cancelReason: false,
      pauseMonth: false,
      loading: false,
      showDowngradeForm: false,
      downgradeDetails: null,
      selectedPlanId: "",
      busy: false,
      cancellationSubscriptionNote: false,
      pauseSubscription: false,
      pauseDetail: null,
      offerMode: false,
      offerDetail: null,
      cancelDetails: [],
      pauseItems: [],
    };
  },
  computed: {
    ...mapGetters({
      plans: "getPlans",
    }),
    plansList() {
      return this.plans && this.plans.plans
        ? this.plans.plans.filter((plan) => plan.meta.category == "starter")
        : [];
    },
  },
  created() {
    this.initiateCancelPopup();
  },
  methods: {
    initiateCancelPopup() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(INIT_CANCEL)
        .then((response) => {
          this.loading = false;
          this.cancelDetails = response.offers;
          this.pauseDetail = this.cancelDetails.find(
            (detail) => detail.name == "pause"
          );
          if (this.pauseDetail && this.pauseDetail.value) {
            for (
              let month = 1;
              month <= parseInt(this.pauseDetail.value.months);
              month++
            ) {
              this.pauseItems.push({
                value: `${month}`,
                text: `${month} month${month > 1 ? "s" : ""}`,
              });
            }
          }
          this.downgradeDetails = this.cancelDetails.find(
            (detail) => detail.name == "downgrade"
          );
          this.offerDetail = this.cancelDetails.find(
            (detail) => detail.name === "discount"
          );
          this.pauseSubscription = !!this.pauseDetail;
          if (!this.pauseSubscription) {
            this.downgradeDetails = this.cancelDetails.find(
              (detail) => detail.name === "downgrade"
            );
            this.showDowngradeForm = !!this.downgradeDetails;
            if (!this.showDowngradeForm) {
              this.offerMode = !!this.offerDetail;
              if (!this.offerMode) {
                this.cancellationSubscriptionNote = true;
              }
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$emit("close");
          this.notifyErrorMessage(err.message);
        });
    },
    getFormattedDate(date) {
      if (!Number.isInteger(date)) {
        return dayjs(date).format("MMM, DD YYYY");
      }
      return dayjs(date * 1000).format("MMM, DD YYYY");
    },
    initiateClaimOffer(claimDetail) {
      if (this.busy) return;
      this.busy = true;
      const dataToPost = {
        id: claimDetail.id,
        value: claimDetail.value || "",
      };
      this.$store
        .dispatch(CLAIM_OFFER, dataToPost)
        .then((response) => {
          this.logEvent(segmentEvents.CANCEL_MODAL, {
            id: claimDetail.id,
            action: "Take offer",
            value: claimDetail.value,
          });
          this.busy = false;
          this.notifyUserMessage({ message: response.message });
          this.$emit("success");
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    changePlan() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(CHANGE_PLANS, { plan_id: this.selectedPlanId })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.$store.dispatch(GET_USER_ROLES).catch((err) => {
            this.notifyErrorMessage(err.message);
          });
          this.initiateClaimOffer({
            id: this.downgradeDetails.id,
            value: this.selectedPlanId,
          });
          this.$emit("close");
          this.changeModal = false;
          this.upgradePlanMessage = "";
          this.busy = false;
          this.$nextTick(async () => {
            await this.getPlans();
          });
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
          this.$nextTick(async () => {
            await this.getPlans();
          });
        });
    },
    previousFromOffer() {
      this.offerMode = false;
      if (this.downgradeDetails) {
        this.showDowngradeForm = true;
      } else if (this.pauseDetail) {
        this.pauseSubscription = true;
      }
      this.logEvent(segmentEvents.CANCEL_MODAL, {
        action: "Clicked on previous",
        previous: this.downgradeDetails ? "Downgrade" : "Pause",
        current: "Discount",
      });
    },
    skipFromPause() {
      this.pauseSubscription = false;
      if (this.downgradeDetails) {
        this.showDowngradeForm = true;
      } else if (this.offerDetail) {
        this.offerMode = true;
      } else {
        this.cancellationSubscriptionNote = true;
      }
      this.logEvent(segmentEvents.CANCEL_MODAL, {
        action: "Declined offer",
        previous: "Cancel information",
        current: "Pause",
      });
    },
    skipFromDowngrade() {
      this.showDowngradeForm = false;
      this.cancellationSubscriptionNote = true;
      this.logEvent(segmentEvents.CANCEL_MODAL, {
        action: "Declined offer",
        previous: this.pauseDetail ? "Pause" : "Cancel information",
        current: "Downgrade",
      });
    },
    previousFromDowngrade() {
      this.showDowngradeForm = false;
      this.pauseSubscription = true;
      this.logEvent(segmentEvents.CANCEL_MODAL, {
        action: "Clicked on previous",
        previous: this.pauseDetail ? "Pause" : "",
        current: "Downgrade",
      });
    },
    skipToCancelModal() {
      (this.offerMode = false), (this.cancellationSubscriptionNote = true);
      this.logEvent(segmentEvents.CANCEL_MODAL, {
        action: "Declined offer",
        previous: this.downgradeDetails ? "Downgrade" : "Pause",
        current: "Discount",
      });
    },
    openCancelReason() {
      this.cancellationSubscriptionNote = false;
      this.cancelReason = true;
    },
    openSchedule() {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/omar-massad/improving-our-services",
      });
    },
  },
};
</script>
