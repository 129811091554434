<template>
  <v-row justify="center" class="my-4">
    <v-col v-if="canManageTeam" cols="12">
      <div class="bodyFont">
        <div v-html="getDiscountMessage"></div>
      </div>
    </v-col>
    <template v-if="isInfoLoaded">
      <v-skeleton-loader type="list"></v-skeleton-loader>
    </template>
    <template v-else>
      <template v-if="canManageTeam">
        <v-col v-if="canManageTeam" cols="12">
          <usage-overview
            class="mb-4"
            :current-subscription="subscription"
            @cancelPlan="openCancelDialog"
            @success="getPlans"
            @switchAnnually="confirmationModal = true"
          ></usage-overview>

          <v-card flat class="pa-4 py-8 mb-5 cardBackground">
            <v-row>
              <v-col class="text-center py-10 semi-bold titleFont" cols="12">
                <div>
                  Upgrade your team's plan and super charge your apps 🚀
                </div>
              </v-col>
              <v-col
                class="text-center"
                v-for="(item, index) in planFeatures"
                :key="index"
                cols="6"
              >
                <div class="py-10">
                  <v-avatar size="60" outline class="avatarbackground">
                    <v-icon
                      size="30"
                      color="success
                  "
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>
                  <div class="bodyFont mt-5 semi-bold">
                    {{ item.title }}
                  </div>
                  <div class="">
                    {{ item.description }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="10">
              <plan-pricing
                @success="getPlans"
                :loading="planLoading"
              ></plan-pricing>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </template>

    <Modal
      v-model="showAddonsForm"
      :width="$vuetify.breakpoint.mdAndUp ? '600' : ''"
      @close="showAddonsForm = false"
    >
      <template #card>
        <v-card class="pa-3">
          <add-addons
            v-if="showAddonsForm"
            :addon-stats="addons"
            :addon-type="addonsType"
            @close="showAddonsForm = false"
            @success="getPlans"
          ></add-addons>
        </v-card>
      </template>
    </Modal>
    <Modal
      v-model="openSubscriptionModal"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
      @close="openSubscriptionModal = false"
    >
      <template #message>
        <plan-pricing
          v-if="openSubscriptionModal"
          :subscribe="subscribe"
          :upgrade-plan-text="'Upgrade your plan'"
          from-modal
          from-plan-page
          from-upgrade-component
          @close="openSubscriptionModal = false"
          @success="$emit('close'), (openSubscriptionModal = false)"
        ></plan-pricing>
      </template>
    </Modal>
    <Modal
      v-model="cancelInformation"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="cancelInformation = false"
    >
      <template #card>
        <v-card>
          <v-card-title>
            <div class="text-center titleFont">Talk with us!</div>
          </v-card-title>
          <v-card-text class="message my-4">
            <v-row>
              <v-col cols="12">
                <v-img
                  contain
                  height="250"
                  src="/media/emoji/cat-sad-cat.gif"
                  width="100%"
                ></v-img>
              </v-col>
              <v-col cols="12">
                If you face issues with the service, plan, or subscription,
                please get in touch with us first before canceling your
                subscription.
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="text-transform-none"
              color="primary"
              text
              @click="cancelInformation = false"
              >Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="text-transform-none"
              color="primary"
              text
              @click="initNevermind"
              >Proceed to cancel
            </v-btn>
            <v-btn
              class="text-transform-none"
              color="primary"
              :outlined="$vuetify.theme.dark"
              depressed
              @click="openIntercomChat(cancelMessage)"
              >Contact us
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal
      v-if="cancelDialog"
      v-model="cancelDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :width="600"
    >
      <template #card>
        <cancellation-modal
          :subscription-info="subscription"
          @close="cancelDialog = false"
          @success="getPlans"
          @cancel-confirm="openCancelUrl"
        ></cancellation-modal>
      </template>
      <!--      <template #message>-->
      <!--        <div class="my-n3">-->
      <!--          <v-card-title class="font-weight-bold">-->
      <!--            Cancel subscription-->
      <!--          </v-card-title>-->
      <!--          <v-divider />-->
      <!--          <v-card-text class="message">-->
      <!--            Cancelling the subscription will be implemented-->
      <!--            <span v-if="subscription.cancellation_effective_date">-->
      <!--              on-->
      <!--              <b-->
      <!--                >{{-->
      <!--                  getFormattedDate(subscription.cancellation_effective_date)-->
      <!--                }}.</b-->
      <!--              >-->
      <!--            </span>-->
      <!--            <span v-else>-->
      <!--              by the next billing cycle-->
      <!--              <b>{{ getFormattedDate(subscription.next_bill_date) }}</b-->
      <!--              >.-->
      <!--            </span>-->
      <!--            <br />-->
      <!--            <br />-->
      <!--            The team will be downgraded to the free plan. Members might lose-->
      <!--            access to certain features when downgrading as well as archiving the-->
      <!--            over-the-limit releases.-->
      <!--            <br />-->
      <!--            <br />-->
      <!--            Once the cancellation implemented, there's no undo but until then-->
      <!--            you can still subscribe again to stop the cancellation order before-->
      <!--            the cancellation date.-->
      <!--            <br />-->

      <!--            <br />-->

      <!--            <v-alert-->
      <!--              class="mt-3"-->
      <!--              border="left"-->
      <!--              icon="info"-->
      <!--              color="primary"-->
      <!--              outlined-->
      <!--            >-->
      <!--              If it is something that we can help with, please do-->
      <!--              <span-->
      <!--                @click="onContactus"-->
      <!--                class="pointer blue&#45;&#45;text text-decoration-underline"-->
      <!--                >contact us</span-->
      <!--              >-->
      <!--              or-->
      <!--              <span-->
      <!--                @click="openSchedule"-->
      <!--                class="pointer blue&#45;&#45;text text-decoration-underline"-->
      <!--              >-->
      <!--                schedule a call-->
      <!--              </span>-->
      <!--              and we'll gladly help you out!-->
      <!--            </v-alert>-->
      <!--          </v-card-text>-->
      <!--          <v-divider />-->
      <!--          <v-card-actions class="pb-0">-->
      <!--            <v-spacer />-->
      <!--            <v-btn-->
      <!--              text-->
      <!--              id="cancelDeclineBtn"-->
      <!--              @click="cancelDialog = false"-->
      <!--              class="text-transform-none"-->
      <!--              color="primary"-->
      <!--              >Close</v-btn-->
      <!--            >-->
      <!--            <v-btn-->
      <!--              id="cancelConfirmBtn"-->
      <!--              @click="openCancelReason"-->
      <!--              color="primary"-->
      <!--              :outlined="$vuetify.theme.dark"-->
      <!--              class="text-transform-none"-->
      <!--              >Got it, cancel it please</v-btn-->
      <!--            >-->
      <!--          </v-card-actions>-->
      <!--        </div>-->
      <!--      </template>-->
    </Modal>
    <Modal
      v-if="openCancelReasonModal"
      v-model="openCancelReasonModal"
      @close="openCancelReasonModal = false"
    >
      <template slot="message">
        <cancel-plan-modal @success="openCancelUrl"></cancel-plan-modal>
      </template>
    </Modal>
    <Modal
      v-model="confirmationModal"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="confirmationModal = false"
    >
      <template #message>
        <v-row class="py-4">
          <v-col class="mt-4 bold-text font-size-h3" cols="12">
            Switch to {{ subscription.name }}
            <span class="text-capitalize">(Annually)</span>
          </v-col>
          <v-col class="bodyFont" cols="12">
            <div class="mb-4">
              Great choice! Reduce payment failures and lock the current
              discount for a year 💪
            </div>
            <div>
              Team: <b>{{ getTeamName }}</b>
            </div>
          </v-col>
          <v-col class="text-center text-h6" cols="12">
            <v-alert color="success" outlined>
              <span class="body-1">
                One-click away from saving <b>30%</b> or nearly
                <b>4 months</b> free 🎉
              </span>
            </v-alert>
            <v-alert color="info" outlined>
              <span class="body-1">
                We will apply the remaining balance to your new billing
              </span>
            </v-alert>
          </v-col>
          <v-col class="text-right pb-0" cols="12">
            <v-btn
              :disabled="busy"
              class="text-transform-none mx-2"
              color="primary"
              text
              @click="confirmationModal = false"
              >Cancel
            </v-btn>
            <v-btn
              id="switchToAnnuallyBtn"
              :loading="busy"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="primary"
              depressed
              @click="changePlan"
              >Switch 🎉
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal v-model="successModal">
      <template v-if="successModal" #message>
        <v-row class="py-4">
          <v-col class="text-center text-h5" cols="12">
            Congratulations 🎉
          </v-col>
          <v-col class="text-center text-h6" cols="12">
            {{ upgradeMessage || "We are upgrading your plan" }}
          </v-col>
          <v-col class="text-center" cols="12">
            <v-progress-circular
              indeterminate
              color="success"
            ></v-progress-circular>
          </v-col>
          <v-col class="text-center bodyFont" cols="12">
            It should be done within few moments
          </v-col>
        </v-row>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import {
  CHANGE_PLANS,
  GET_PLANS,
  START_FREE_TRIAL,
} from "@/store/team/team.module";
import { GET_USER_ROLES } from "@/store/users/auth.module";
import { mapGetters } from "vuex";
import {
  formatBytes,
  get,
  getDurationOnDays,
  isEmpty,
} from "@/core/services/helper.service";
import AddAddons from "@/view/components/Team/AddAddons";
import dayjs from "dayjs";
import PlanPricing from "@/view/components/Team/PlanPricing";
import CancelPlanModal from "@/view/components/Team/CancelPlanModal";
import CancellationModal from "@/view/components/Team/CancellationModal";
import * as Sentry from "@sentry/vue";
import { segmentEvents } from "@/consts/segmentEventConst";
import UsageOverview from "@/view/components/Common/UsageOverview";

export default {
  components: {
    UsageOverview,
    CancelPlanModal,
    PlanPricing,
    CancellationModal,
    AddAddons,
  },
  data() {
    return {
      formatBytes,
      get,
      isEmpty,
      getDurationOnDays,
      successModal: false,
      upgradeMessage: null,
      planFeatures: [
        {
          icon: "mdi-plus-circle-outline",
          title: "Expand Your Portfolio",
          description:
            "Deploy and manage multiple apps across Android & iOS. Assign roles, streamline processes, and boost efficiency.",
        },
        {
          icon: "mdi-database",
          title: "Built for Teams",
          description:
            "Strengthen team collaboration with clear communication channels. Ensure seamless integration of development, QA, and testing.",
        },
        {
          icon: "mdi-account-group",
          title: "In-app Sessions",
          description:
            "Gain insights into user behaviors in real-time. Enhance the user journey, drive engagement, and boost retention rates.",
        },
        {
          icon: "mdi-chart-line-variant",
          title: "Advanced Insights",
          description:
            "Leverage in-depth metrics to fine-tune your app. Analyze patterns, refine strategies, and achieve peak performance.",
        },
      ],
      subscribe: false,
      cancelDialog: false,
      confirmationModal: false,
      cancelInformation: false,
      openCancelReasonModal: false,
      showAddonsForm: false,
      openSubscriptionModal: false,
      addonsType: "",
      tab: 0,
      busy: false,
      planLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      plans: "getPlansDetail",
      user: "currentUser",
      getTeamName: "getTeamName",
      getDiscountMessage: "getDiscountMessage",
      getTeamPlanAnalytics: "getTeamPlanAnalytics",
      getTeamPlanUsage: "getTeamPlanUsage",
      getTeamTrial: "getTeamTrial",
    }),
    cancelMessage() {
      return {
        message: `Hello, I have a question about ${this.getTeamName} subscription`,
        subject: `A question about ${this.getTeamName} subscription`,
      };
    },
    info() {
      return this.plans.info ? this.plans.info.team_usage : {};
    },
    currentPlanData() {
      return this.plans.info ? this.plans.info.plan_usage : {};
    },
    getPromoInfo() {
      return this.plans.info ? this.plans.info.promo : {};
    },
    extraFreeApp() {
      let totalLimit =
        parseInt(
          this.subscription && this.subscription.limits
            ? this.subscription.limits.apps || 0
            : 0
        ) + parseInt(this.addons ? this.addons.apps || 0 : 0);
      return (
        parseInt(
          this.currentPlanData && this.currentPlanData.limits
            ? this.currentPlanData.limits.apps || 0
            : 0
        ) - totalLimit
      );
    },
    showStartTrialAlert() {
      return !!(
        isEmpty(this.getTeamTrial) &&
        !!this.getTeamId &&
        !this.getIsUserDisabled
      );
    },
    showActiveTrialAlert() {
      return (
        !isEmpty(this.getTeamTrial) &&
        !this.hideActiveTrial &&
        this.getTeamTrial.status === "active"
      );
    },
    addons() {
      return this.plans.info && this.plans.info.addons
        ? this.plans.info.addons
        : {};
    },
    appsAddonPrice() {
      return (
        (this.addons.apps || 0) * (this.subscription.name === "Starter" ? 1 : 3)
      );
    },
    membersAddonPrice() {
      return (
        (this.addons.members || 0) *
        (this.subscription.name === "Starter" ? 2 : 4)
      );
    },
    extraFreeMember() {
      let totalLimit =
        parseInt(
          this.subscription && this.subscription.limits
            ? this.subscription.limits.members || 0
            : 0
        ) + parseInt(this.addons ? this.addons.members || 0 : 0);
      return (
        parseInt(
          this.currentPlanData && this.currentPlanData.limits
            ? this.currentPlanData.limits.members || 0
            : 0
        ) - totalLimit
      );
    },
    getSelectedPlanPrice() {
      return this.plans.plans &&
        this.plans.plans.find((plan) => plan.id === this.subscription.id)
        ? this.plans.plans.find((plan) => plan.id === this.subscription.id)
            .price
        : "";
    },
    getAnnualPlanId() {
      return this.plans.plans &&
        this.plans.plans.find(
          (plan) =>
            plan.name === this.subscription.name &&
            plan.recurring === "annually"
        )
        ? this.plans.plans.find(
            (plan) =>
              plan.name === this.subscription.name &&
              plan.recurring === "annually"
          ).id
        : "";
    },
    subscription() {
      return this.plans && this.plans.info ? this.plans.info.subscription : {};
    },
    addonsPrice() {
      const currentActivePlan = this.plans.plans.find(
        (plan) => plan.id === this.subscription.id
      );
      return currentActivePlan ? currentActivePlan.addons_price : {};
    },
  },
  created() {
    if (!this.plans.length) {
      this.getPlans();
    }
  },
  methods: {
    getPlans() {
      this.cancelDialog = false;
      if (this.planLoading) return;
      this.planLoading = true;
      this.$store
        .dispatch(GET_PLANS)
        .then(() => {
          this.planLoading = false;
          if (
            this.$route.query.action &&
            this.$route.query.action === "update-billing"
          ) {
            this.openUpdateUrl();
          }
          if (
            this.$route.query.action &&
            this.$route.query.action === "switch-to-annually"
          ) {
            this.confirmationModal = true;
          }
          if (
            this.subscription.status === "paused" &&
            !this.subscription.paused_at
          ) {
            Sentry.captureMessage("No paused at while status is paused.");
          }
        })
        .catch((err) => {
          if (this.canManageTeam) {
            this.notifyErrorMessage(err.message);
          }
          this.planLoading = false;
        });
    },
    openSchedule() {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/omar-massad/book-a-demo",
      });
    },
    openUpdateUrl() {
      this.logEvent(segmentEvents.UPDATE_BILLING);

      let self = this;
      window.Paddle.Checkout.open({
        override:
          this.subscription && this.subscription.urls
            ? this.subscription.urls.update
            : "",
        successCallback: function () {
          self.logEvent(segmentEvents.UPDATE_BILLING);
          window.Paddle.Spinner.show();
          setTimeout(() => {
            self.notifyUserMessage(
              "Billing Information Processed! Your updates have been successfully applied. Note: We do not store your billing details"
            );
            self.getPlans();
            window.Paddle.Spinner.hide();
          }, 5000);
        },
        closeCallback: function () {
          self.logEvent(segmentEvents.CLOSED_UPDATE, {
            plan_id: self.subscription.id,
            plan_name: self.subscription.name,
          });
        },
      });
    },
    openSubscription() {
      this.subscribe = true;
      this.openSubscriptionModal = true;
    },
    initNevermind() {
      this.cancelInformation = false;
      this.cancelDialog = true;
      this.logEvent(segmentEvents.CANCEL_MODAL, {
        action: "Never mind",
        step: "Contact us",
      });
    },
    initContactus() {
      this.cancelInformation = false;
      this.onContactus();
      this.logEvent(segmentEvents.CANCEL_MODAL, {
        action: "Clicked contact us",
        step: "Contact us",
      });
    },

    openTrial() {
      this.subscribe = false;
      this.openSubscriptionModal = true;
    },
    openCancelReason() {
      this.cancelDialog = false;
      this.openCancelReasonModal = true;
    },
    openCancelDialog() {
      this.cancelInformation = true;
      // this.cancelDialog = true;
      this.logEvent("Opened cancelled popup");
    },
    openCancelUrl() {
      this.openCancelReasonModal = false;
      this.logEvent(segmentEvents.CANCEL_PLAN);

      this.cancelDialog = false;
      let self = this;
      window.Paddle.Checkout.open({
        override: this.subscription.urls.cancel,
        successCallback: function () {
          self.logEvent(segmentEvents.CANCEL_MODAL, {
            plan_id: self.subscription.id,
            plan_name: self.subscription.name,
          });
          window.Paddle.Spinner.show();
          setTimeout(() => {
            self.notifyUserMessage(
              "Your plan has been scheduled for cancellation"
            );
            self.getPlans();
            window.Paddle.Spinner.hide();
          }, 5000);
        },
        closeCallback: function () {
          self.logEvent(segmentEvents.CANCEL_MODAL, {
            plan_id: self.subscription.id,
            plan_name: self.subscription.name,
          });
        },
      });
    },
    startTrial() {
      if (this.loading) return;
      this.loading = true;
      let planId = "V3GoPO2xXy";
      this.$store
        .dispatch(START_FREE_TRIAL, { plan_id: planId })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.$store.dispatch(GET_USER_ROLES).catch((err) => {
            this.notifyErrorMessage(err.message);
          });
          this.$nextTick(() => {
            this.getPlans();
          });
          this.loading = false;
          this.$emit("close");
          this.showStartTrialModal = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    changePlan() {
      this.upgradeMessage = null;
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(CHANGE_PLANS, { plan_id: this.getAnnualPlanId })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.$nextTick(() => {
            this.upgradeMessage = response.message;
            this.successModal = true;
            setTimeout(() => {
              this.successModal = false;
              window.location.reload();
            }, 5000);
          });
          this.confirmationModal = false;
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    getFormattedDate(date) {
      if (!Number.isInteger(date)) {
        return dayjs(date).format("MMM, DD YYYY");
      }
      return dayjs(date * 1000).format("MMM, DD YYYY");
    },
    isTrialExpiring() {
      const expiryDate = new Date(
        `${dayjs(this.getTeamTrial.end_at * 1000)}`
      ).getTime();
      const currentDate = new Date().getTime();
      const diffDate = Math.ceil(
        (expiryDate - currentDate) / (1000 * 3600 * 24)
      );
      return diffDate <= 3;
    },
    openAddonsDialog(type) {
      this.addonsType = type;
      this.showAddonsForm = true;
    },
  },
};
</script>

<style scoped>
.avatarbackground {
  background: rgba(10, 187, 135, 0.07);
}
.cardBackground {
  border: 1px solid;

  border-image-source: linear-gradient(265.68deg, #0abb87 0%, #4062d7 100%);
  border-radius: 20px;
  background: linear-gradient(
    -60deg,
    rgba(10, 187, 135, 0.06),
    rgba(64, 98, 215, 0.06)
  );
}
</style>
