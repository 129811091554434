var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pa-4"
  }, [_c('v-col', {
    staticClass: "font-size-h3 bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Cancellation Reason ")]), _c('v-col', {
    staticClass: "pb-0 bodyFont"
  }, [_vm._v(" Providing the reason is totally optional but it's a great help for us if you do provide it. ")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: '',
      expression: "''"
    }],
    attrs: {
      "filled": "",
      "items": _vm.cancelReasons,
      "hide-details": _vm.formValues.reason === 'other',
      "placeholder": "Select the reason"
    },
    model: {
      value: _vm.formValues.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "reason", $$v);
      },
      expression: "formValues.reason"
    }
  }, 'v-select', _vm.veeValidate('Reason', ''), false)), _vm.formValues.reason === 'Other' ? _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'min:3|max:100',
      expression: "'min:3|max:100'"
    }],
    attrs: {
      "filled": "",
      "placeholder": "What is the main reason to cancel?",
      "counter": "100"
    },
    model: {
      value: _vm.formValues.other_reason,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "other_reason", $$v);
      },
      expression: "formValues.other_reason"
    }
  }, 'v-text-field', _vm.veeValidate('reason', ''), false)) : _vm._e(), _c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    attrs: {
      "filled": "",
      "counter": "600",
      "placeholder": "Can you please explain the reason or maybe a feedback for us?"
    },
    model: {
      value: _vm.formValues.feedback,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "feedback", $$v);
      },
      expression: "formValues.feedback"
    }
  }, 'v-textarea', _vm.veeValidate('Feedback', ''), false))], 1), _c('v-col', {
    staticClass: "pt-0 pb-4 bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" If it is something that we can help with, please do "), _c('span', {
    staticClass: "pointer blue--text text-decoration-underline",
    on: {
      "click": _vm.onContactus
    }
  }, [_vm._v("contact us")]), _vm._v(" or "), _c('span', {
    staticClass: "pointer blue--text text-decoration-underline",
    on: {
      "click": _vm.openSchedule
    }
  }, [_vm._v(" schedule a call ")]), _vm._v(" and we'll gladly help you out! ")])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none mx-2",
    attrs: {
      "text": "",
      "disabled": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "depressed": "",
      "outlined": _vm.$vuetify.theme.dark,
      "loading": _vm.loading,
      "disabled": _vm.errors.any()
    },
    on: {
      "click": _vm.cancelPlanReason
    }
  }, [_vm._v(" Submit & Proceed ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }