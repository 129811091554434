var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "my-4",
    attrs: {
      "justify": "center"
    }
  }, [_vm.canManageTeam ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.getDiscountMessage)
    }
  })])]) : _vm._e(), _vm.isInfoLoaded ? [_c('v-skeleton-loader', {
    attrs: {
      "type": "list"
    }
  })] : [_vm.canManageTeam ? [_vm.canManageTeam ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('usage-overview', {
    staticClass: "mb-4",
    attrs: {
      "current-subscription": _vm.subscription
    },
    on: {
      "cancelPlan": _vm.openCancelDialog,
      "success": _vm.getPlans,
      "switchAnnually": function switchAnnually($event) {
        _vm.confirmationModal = true;
      }
    }
  }), _c('v-card', {
    staticClass: "pa-4 py-8 mb-5 cardBackground",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center py-10 semi-bold titleFont",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" Upgrade your team's plan and super charge your apps 🚀 ")])]), _vm._l(_vm.planFeatures, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "text-center",
      attrs: {
        "cols": "6"
      }
    }, [_c('div', {
      staticClass: "py-10"
    }, [_c('v-avatar', {
      staticClass: "avatarbackground",
      attrs: {
        "size": "60",
        "outline": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "30",
        "color": "success\n                "
      }
    }, [_vm._v(" " + _vm._s(item.icon) + " ")])], 1), _c('div', {
      staticClass: "bodyFont mt-5 semi-bold"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {}, [_vm._v(" " + _vm._s(item.description) + " ")])], 1)]);
  })], 2)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('plan-pricing', {
    attrs: {
      "loading": _vm.planLoading
    },
    on: {
      "success": _vm.getPlans
    }
  })], 1)], 1)], 1)] : _vm._e()], _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? '600' : ''
    },
    on: {
      "close": function close($event) {
        _vm.showAddonsForm = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', {
          staticClass: "pa-3"
        }, [_vm.showAddonsForm ? _c('add-addons', {
          attrs: {
            "addon-stats": _vm.addons,
            "addon-type": _vm.addonsType
          },
          on: {
            "close": function close($event) {
              _vm.showAddonsForm = false;
            },
            "success": _vm.getPlans
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showAddonsForm,
      callback: function callback($$v) {
        _vm.showAddonsForm = $$v;
      },
      expression: "showAddonsForm"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : ''
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.openSubscriptionModal ? _c('plan-pricing', {
          attrs: {
            "subscribe": _vm.subscribe,
            "upgrade-plan-text": 'Upgrade your plan',
            "from-modal": "",
            "from-plan-page": "",
            "from-upgrade-component": ""
          },
          on: {
            "close": function close($event) {
              _vm.openSubscriptionModal = false;
            },
            "success": function success($event) {
              _vm.$emit('close'), _vm.openSubscriptionModal = false;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.cancelInformation = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', [_c('div', {
          staticClass: "text-center titleFont"
        }, [_vm._v("Talk with us!")])]), _c('v-card-text', {
          staticClass: "message my-4"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-img', {
          attrs: {
            "contain": "",
            "height": "250",
            "src": "/media/emoji/cat-sad-cat.gif",
            "width": "100%"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" If you face issues with the service, plan, or subscription, please get in touch with us first before canceling your subscription. ")])], 1)], 1), _c('v-card-actions', [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.cancelInformation = false;
            }
          }
        }, [_vm._v("Close ")]), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.initNevermind
          }
        }, [_vm._v("Proceed to cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "outlined": _vm.$vuetify.theme.dark,
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openIntercomChat(_vm.cancelMessage);
            }
          }
        }, [_vm._v("Contact us ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.cancelInformation,
      callback: function callback($$v) {
        _vm.cancelInformation = $$v;
      },
      expression: "cancelInformation"
    }
  }), _vm.cancelDialog ? _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": 600
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('cancellation-modal', {
          attrs: {
            "subscription-info": _vm.subscription
          },
          on: {
            "close": function close($event) {
              _vm.cancelDialog = false;
            },
            "success": _vm.getPlans,
            "cancel-confirm": _vm.openCancelUrl
          }
        })];
      },
      proxy: true
    }], null, false, 1385549097),
    model: {
      value: _vm.cancelDialog,
      callback: function callback($$v) {
        _vm.cancelDialog = $$v;
      },
      expression: "cancelDialog"
    }
  }) : _vm._e(), _vm.openCancelReasonModal ? _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.openCancelReasonModal = false;
      }
    },
    model: {
      value: _vm.openCancelReasonModal,
      callback: function callback($$v) {
        _vm.openCancelReasonModal = $$v;
      },
      expression: "openCancelReasonModal"
    }
  }, [_c('template', {
    slot: "message"
  }, [_c('cancel-plan-modal', {
    on: {
      "success": _vm.openCancelUrl
    }
  })], 1)], 2) : _vm._e(), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.confirmationModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "mt-4 bold-text font-size-h3",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Switch to " + _vm._s(_vm.subscription.name) + " "), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("(Annually)")])]), _c('v-col', {
          staticClass: "bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "mb-4"
        }, [_vm._v(" Great choice! Reduce payment failures and lock the current discount for a year 💪 ")]), _c('div', [_vm._v(" Team: "), _c('b', [_vm._v(_vm._s(_vm.getTeamName))])])]), _c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-alert', {
          attrs: {
            "color": "success",
            "outlined": ""
          }
        }, [_c('span', {
          staticClass: "body-1"
        }, [_vm._v(" One-click away from saving "), _c('b', [_vm._v("30%")]), _vm._v(" or nearly "), _c('b', [_vm._v("4 months")]), _vm._v(" free 🎉 ")])]), _c('v-alert', {
          attrs: {
            "color": "info",
            "outlined": ""
          }
        }, [_c('span', {
          staticClass: "body-1"
        }, [_vm._v(" We will apply the remaining balance to your new billing ")])])], 1), _c('v-col', {
          staticClass: "text-right pb-0",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none mx-2",
          attrs: {
            "disabled": _vm.busy,
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.confirmationModal = false;
            }
          }
        }, [_vm._v("Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "switchToAnnuallyBtn",
            "loading": _vm.busy,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": _vm.changePlan
          }
        }, [_vm._v("Switch 🎉 ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.confirmationModal,
      callback: function callback($$v) {
        _vm.confirmationModal = $$v;
      },
      expression: "confirmationModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([_vm.successModal ? {
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "text-center text-h5",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Congratulations 🎉 ")]), _c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" " + _vm._s(_vm.upgradeMessage || "We are upgrading your plan") + " ")]), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": "",
            "color": "success"
          }
        })], 1), _c('v-col', {
          staticClass: "text-center bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" It should be done within few moments ")])], 1)];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.successModal,
      callback: function callback($$v) {
        _vm.successModal = $$v;
      },
      expression: "successModal"
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }