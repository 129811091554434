<template>
  <div class="pa-3">
    <v-row class="darkGrey--text">
      <v-col cols="12" md="4">
        <v-card class="pa-3" height="100%" outlined>
          <div class="bodyFont mb-3">Current billing</div>
          <div
            v-if="subscription.status && subscription.status === 'cancelled'"
            class="titleFont bold-text"
          >
            $0
          </div>
          <div v-else class="titleFont bold-text">
            <span v-if="getPromoInfo && getPromoInfo.source_name">
              $ {{ getPromoInfo.price || "0" }}
            </span>
            <span v-else> $ {{ currentSubscription.price || "0" }} </span>
            <span v-if="currentSubscription.recurring" class="font14">
              / {{ currentSubscription.recurring }}</span
            >
          </div>
          <div
            v-if="
              currentSubscription.recurring == 'monthly' &&
              getTeamTrial &&
              getTeamTrial.status !== 'active' &&
              subscription.status === 'active'
            "
            class="primary--text cursor-pointer"
            @click="$emit('switchAnnually')"
          >
            Switch to yearly (save 30% or nearly 4 months free)
          </div>
          <div
            v-if="
              getTeamTrial &&
              getTeamTrial.status &&
              getTeamTrial.status !== 'upgraded' &&
              getPromoInfo &&
              !getPromoInfo.source_name
            "
            :class="
              getTeamTrial.status == 'active' ? 'success--text' : 'error--text'
            "
            class="bodyFont"
          >
            {{ getTeamTrial.status == "active" ? "Active trial" : "" }}
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="pa-3" height="100%" outlined>
          <span v-if="subscription.status === 'paused'">
            <span class="success--text"> Paused </span>
          </span>
          <span v-if="subscription.status === 'suspended'">
            <span class="error--text"> Suspended </span>
          </span>
          <div class="bodyFont mb-2">
            <div v-if="getTeamTrial && getTeamTrial.status == 'active'">
              Trial end date
            </div>
            <div
              v-if="
                subscription.status &&
                ['active', 'cancelled'].includes(subscription.status)
              "
            >
              Next payment due
            </div>
            <div v-if="subscription.status && subscription.status == 'paused'">
              {{ getFormattedDate(subscription.paused_at) }}
            </div>
            <div
              v-if="subscription.status === 'suspended'"
              class="bodyFont regular-font mt-4"
            >
              Temporarily suspended due to many failed renewal attempts, please
              update your billing details to resume it.

              <br />
              <br />
              If you have any questions, feel free to
              <span
                @click="onContactus"
                class="primary--text cursor-pointer text-decoration-underline"
                >contact us</span
              >.
            </div>
            <div
              v-if="
                !subscription.status &&
                getTeamTrial &&
                getTeamTrial.status != 'active'
              "
            >
              Next payment due
            </div>
            <div v-if="subscription.status && subscription.status === 'cancel'">
              Cancellation effective date
            </div>
          </div>
          <div
            class="titleFont mb-2 bold-text"
            v-if="subscription.status === 'paused'"
          >
            <div
              class="bodyFont regular-font mt-4"
              v-if="currentSubscription.resume_at"
            >
              Resume at
              {{ getFormattedDate(currentSubscription.resume_at) }}
            </div>
            <template v-else>
              <div
                class="font11 text-decoration-line-through"
                v-if="
                  showOldBillingDate && currentSubscription.old_next_bill_date
                "
              >
                {{
                  getFormattedDate(currentSubscription.old_next_bill_date) ||
                  "-"
                }}
              </div>
              <div v-if="currentSubscription.next_bill_date">
                {{
                  getFormattedDate(currentSubscription.next_bill_date) || "-"
                }}
              </div>
              <div
                v-else
                class="cursor-pointer cardTitleFont text-decoration-underline primary--text"
                @click="onContactus"
              >
                Contact us
              </div>
            </template>
            <!--            <div  class="cursor-pointer font17 text-decoration-underline primary&#45;&#45;text" @click="initiateResumePlan">-->
            <!--              Resume-->
            <!--            </div>-->
          </div>

          <div
            v-if="
              getTeamTrial &&
              getTeamTrial.status !== 'active' &&
              currentSubscription.status == 'active' &&
              getPromoInfo &&
              !getPromoInfo.source_name
            "
            class="titleFont mb-2 bold-text"
          >
            <div v-if="currentSubscription.next_bill_date">
              {{ getFormattedDate(currentSubscription.next_bill_date) || "-" }}
            </div>
            <div
              v-else
              class="cursor-pointer cardTitleFont text-decoration-underline primary--text"
              @click="onContactus"
            >
              Contact us
            </div>
          </div>
          <div
            v-if="
              !subscription.status &&
              getTeamTrial &&
              getTeamTrial.status !== 'active'
            "
            class="titleFont mb-2 bold-text"
          >
            -
          </div>
          <div
            v-if="getPromoInfo && getPromoInfo.source_name"
            class="titleFont mb-2 bold-text"
          >
            -
          </div>
          <div
            v-if="subscription.status && subscription.status == 'cancelled'"
            class="titleFont mb-2 bold-text"
          >
            -
          </div>
          <div
            v-if="
              getTeamTrial &&
              getTeamTrial.status !== 'active' &&
              currentSubscription.status == 'cancel' &&
              getPromoInfo &&
              !getPromoInfo.source_name
            "
            class="titleFont mb-2 bold-text"
          >
            {{
              currentSubscription.cancellation_effective_date
                ? getFormattedDate(
                    currentSubscription.cancellation_effective_date
                  )
                : "-"
            }}
          </div>
          <div
            v-if="getTeamTrial && getTeamTrial.status === 'active'"
            class="titleFont bold-text"
          >
            {{ getFormattedDate(getTeamTrial.end_at) || "-" }}
          </div>
          <div
            v-if="
              getTeamTrial &&
              getTeamTrial.status === 'active' &&
              getPromoInfo &&
              !getPromoInfo.source_name
            "
            class="primary--text cursor-pointer"
            @click="openIntercomChat(contactMessage)"
          >
            Contact us to extend trial
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="pa-3" height="100%" outlined>
          <div class="bodyFont mb-3">Payment information</div>
          <div
            v-if="
              ['active', 'suspended', 'past_due', 'paused'].includes(
                subscription.status
              ) &&
              getPromoInfo &&
              !getPromoInfo.source_name
            "
          >
            <div
              v-if="subscription.urls && subscription.urls.update"
              class="primary--text cursor-pointer text-decoration-underline"
              @click="openUpdateUrl"
            >
              Update billing info
            </div>

            <div
              v-if="
                subscription.status !== 'paused' &&
                subscription.urls &&
                subscription.urls.cancel
              "
              class="primary--text text-decoration-underline cursor-pointer"
              @click="$emit('cancelPlan')"
            >
              Cancel plan
            </div>
            <v-tooltip
              v-else-if="subscription.status === 'paused'"
              bottom
              color="primary"
            >
              <template v-slot:activator="{ on }">
                <div class="text-muted cursor-pointer" v-on="on">
                  Cancel plan
                </div>
              </template>
              <div>Subscription is currently paused</div>
            </v-tooltip>
          </div>
          <div v-else class="titleFont bold-text">-</div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card v-if="subscription.message" class="pa-4 mb-3" outlined>
          <div v-html="subscription.message"></div>
        </v-card>
        <v-card
          v-if="subscription.status === 'cancel'"
          class="px-4 py-4 mb-3"
          outlined
        >
          <div v-if="subscription.status === 'cancel'" class="bodyFont">
            We are sorry to see you leave 😔
            <br /><br />
            As you requested, your
            <b>{{ subscription.name }} ({{ subscription.recurring }})</b>
            subscription is scheduled for cancellation
            <span v-if="subscription.cancellation_effective_date">
              on
              <b
                >{{
                  getFormattedDate(subscription.cancellation_effective_date)
                }}.</b
              ><br /><br />
              You can resubscribe at any time, and we hope you decide to come
              back soon.
              <br /><br />
              Thank you for your support!
            </span>
          </div>
        </v-card>
        <v-card outlined>
          <v-card-title>
            <div>
              <div class="subListFont">
                <v-icon left> mdi-water-circle </v-icon>
                Current Usage
              </div>
              <div v-if="subscription.custom" class="muted font14">
                <v-icon color="success" small>mdi-star</v-icon>
                This team is on a custom plan and it's better than the current
                one
                <VueTooltip
                  :v-html="'As a thank you for being an early joiner, you will always stay on the best plan! <br /> <br /> If your team decides to upgrade, please contact us for a custom discount 💪'"
                  color="primary"
                  icon-color="grey"
                  text-class="white--text"
                />
              </div>
            </div>
            <v-spacer />
            <!-- <v-btn
              class="text-transform-none"
              color="primary"
              outlined
              @click="openAllPlan"
            >
              Compare all plans
            </v-btn>
            <v-btn
              id="updatePlanButton"
              :disabled="!canManageTeam"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none mx-3"
              color="primary"
              @click="$router.push({ name: 'team-plan' })"
            >
              <span class="font14">
                {{
                  currentSubscription.status === "active"
                    ? "Update plan"
                    : "Upgrade now"
                }}
              </span>
            </v-btn> -->
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col class="bodyFont" cols="12" md="5">Team members</v-col>
                  <v-col class="mx-2 bodyFont">
                    {{ info ? info.total_members || "0" : "0" }} /
                    {{
                      get(currentPlanData, "limits.members", "0") == 0
                        ? "∞"
                        : get(currentPlanData, "limits.members", "∞")
                    }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="bodyFont" cols="12" md="5">Apps</v-col>
                  <v-col class="mx-2 bodyFont">
                    {{ info ? info.total_apps || "0" : "0" }} /
                    {{
                      get(currentPlanData, "limits.apps", "0") == 0
                        ? "∞"
                        : get(currentPlanData, "limits.apps", "∞")
                    }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="bodyFont" cols="12" md="5">Storage</v-col>
                  <v-col class="bodyFont">
                    {{
                      info?.storage >= 0 ? formatBytes(info?.storage || 0) : "0"
                    }}
                    /
                    {{ formatBytes(get(plans, "info.plan_usage.storage", 0)) }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <Modal
        v-model="confirmationModal"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        @close="confirmationModal = false"
      >
        <template #message>
          <v-row class="py-4">
            <v-col class="mt-4 bold-text font-size-h3" cols="12">
              Switch to {{ subscription.name }}
              <span class="text-capitalize">(Annually)</span>
            </v-col>
            <v-col class="bodyFont" cols="12">
              <div class="mb-4">
                Great choice! Reduce payment failures and lock the current
                discount for a year 💪
              </div>
              <div>
                Team: <b>{{ getTeamName }}</b>
              </div>
            </v-col>
            <v-col class="text-center text-h6" cols="12">
              <v-alert color="success" outlined>
                <span class="body-1">
                  One-click away from saving <b>30%</b> or nearly
                  <b>4 months</b> free 🎉
                </span>
              </v-alert>
              <v-alert color="info" outlined>
                <span class="body-1">
                  We will apply the remaining balance to your new billing
                </span>
              </v-alert>
            </v-col>
            <v-col class="text-right pb-0" cols="12">
              <v-btn
                :disabled="busy"
                class="text-transform-none mx-2"
                color="primary"
                text
                @click="confirmationModal = false"
                >Cancel
              </v-btn>
              <v-btn
                id="switchToAnnuallyBtn"
                :loading="busy"
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                color="primary"
                depressed
                @click="$emit('change-plan')"
                >Switch 🎉
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </Modal>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  formatBytes,
  get,
  getDurationOnDays,
  isEmpty,
  numberWithCommas,
} from "@/core/services/helper.service";
import dayjs from "dayjs";
import { GET_PLANS, RESUME_PLAN } from "@/store/team/team.module";
import { segmentEvents } from "@/consts/segmentEventConst";
import * as Sentry from "@sentry/vue";

export default {
  name: "usageOverview",
  data() {
    return {
      formatBytes,
      get,
      isEmpty,
      getDurationOnDays,
      numberWithCommas,
      contactMessage: {
        message:
          "Hello, we would like to extend the trial period for our team.",
        subject: "Support Needed for extending trial",
      },
      confirmationModal: false,
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      plans: "getPlansDetail",
      getCurrentPlan: "getCurrentPlan",
      getTeamName: "getTeamName",
      getTeamPlanAnalytics: "getTeamPlanAnalytics",
      getTeamPlanUsage: "getTeamPlanUsage",
      getTeamTrial: "getTeamTrial",
    }),
    currentSubscription() {
      return this.subscription;
    },
    showOldBillingDate() {
      if (this.subscription.old_next_bill_date) {
        let oldDate = dayjs(this.subscription.old_next_bill_date);
        let newDate = dayjs("2022-05-20");
        let duration = newDate > oldDate;
        return duration;
      } else {
        return false;
      }
    },
    getPromoInfo() {
      return this.plans.info ? this.plans.info.promo : {};
    },
    showActiveTrialAlert() {
      return (
        !isEmpty(this.getTeamTrial) && this.getTeamTrial.status === "active"
      );
    },
    subscription() {
      return this.plans && this.plans.info ? this.plans.info.subscription : {};
    },
    getSelectedPlanPrice() {
      return this.plans.plans &&
        this.plans.plans.find((plan) => plan.id === this.subscription.id)
        ? this.plans.plans.find((plan) => plan.id === this.subscription.id)
            .price
        : "";
    },
    storageTotal() {
      return (
        ((this.info ? this.info.storage : 0) /
          (this.plans && this.plans.info && this.plans.info.plan_usage
            ? this.plans.info.plan_usage.storage
            : 0)) *
        100
      ).toFixed(2);
    },
    publicInstallsTotal() {
      return (
        ((this.info ? parseInt(get(this.info, "installs.public", 0)) : 0) /
          (this.plans &&
          this.plans.info &&
          get(this.plans, "info.team_usage.installs.public", null)
            ? parseInt(this.plans.info.plan_usage.limits.installs.public || 0)
            : 0)) *
        100
      );
    },
    currentPlanData() {
      return this.plans.info ? this.plans.info.plan_usage : {};
    },
    info() {
      return this.plans.info ? this.plans.info.team_usage : {};
    },
  },
  methods: {
    initiateResumePlan() {
      this.$store
        .dispatch(RESUME_PLAN)
        .then((response) => {
          this.$emit("success");
          this.notifyUserMessage({ message: response.message });
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        });
    },

    onContactus() {
      if (window && window.Intercom) {
        window.Intercom("show");
        this.logEvent("contact us modal", {
          status: "success",
          action: "contact_us",
        });
      } else {
        window.open("https://testapp.io/contact", "_blank");
        this.logEvent("redirect to landing page", {
          status: "success",
          action: "contact_us",
        });
      }
    },
    emailInvoices() {
      window.Paddle.User.History(
        this.currentSubscription.email,
        null,
        (response) => {
          if (response.success) {
            this.logEvent(segmentEvents.EMAIL_INVOICE_SUCCESS, {
              from: "plans",
            });
            this.notifyUserMessage({
              message: "Invoice successfully mailed to your email",
            });
          } else {
            this.logEvent(segmentEvents.EMAIL_INVOICE_FAILED, {
              from: "plans",
            });
          }
        }
      );
    },
    openAllPlan() {
      window.open("https://testapp.io/pricing", "_blank");
    },
    openUpdateUrl() {
      this.logEvent(segmentEvents.UPDATE_BILLING);

      let self = this;
      window.Paddle.Checkout.open({
        override:
          this.currentSubscription && this.currentSubscription.urls
            ? this.currentSubscription.urls.update
            : "",
        successCallback: function () {
          self.logEvent(segmentEvents.UPDATE_BILLING);
          window.Paddle.Spinner.show();
          setTimeout(() => {
            self.notifyUserMessage(
              "Billing Information Processed! Your updates have been successfully applied. Note: We do not store your billing details"
            );
            self.getPlans();
            window.Paddle.Spinner.hide();
          }, 5000);
        },
        closeCallback: function () {
          self.logEvent(segmentEvents.CLOSED_UPDATE, {
            plan_id: self.currentSubscription.id,
            plan_name: self.currentSubscription.name,
          });
        },
      });
    },
    getPlans() {
      this.cancelDialog = false;
      if (this.planLoading) return;
      this.planLoading = true;
      this.$store
        .dispatch(GET_PLANS)
        .then(() => {
          this.planLoading = false;
          if (
            this.$route.query.action &&
            this.$route.query.action === "update-billing"
          ) {
            this.openUpdateUrl();
          }
          if (
            this.$route.query.action &&
            this.$route.query.action === "switch-to-annually"
          ) {
            this.confirmationModal = true;
          }
          if (
            this.subscription.status === "paused" &&
            !this.subscription.paused_at
          ) {
            Sentry.captureMessage("No paused at while status is paused.");
          }
        })
        .catch((err) => {
          if (this.canManageTeam) {
            this.notifyErrorMessage(err.message);
          }
          this.planLoading = false;
        });
    },
    getFormattedDate(date) {
      if (!Number.isInteger(date)) {
        return dayjs(date).format("MMM, DD YYYY");
      }
      return dayjs(date * 1000).format("MMM, DD YYYY");
    },
  },
};
</script>
