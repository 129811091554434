var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_vm.loading ? [_c('v-card-text', {
    staticClass: "my-9"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("Please wait...")])], 1)], 1)] : [_vm.pauseSubscription ? [_c('v-card-title', [_c('v-spacer'), _c('div', {
    staticClass: "text-center semi-bold titleFont"
  }, [_vm._v(" How about pausing instead? ")]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "text-center bodyFont mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Pausing your subscription guarantees that you'll keep your team members, storage, releases, apps safe without being billed. ")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont mb-2"
  }, [_vm._v(" How long would you like to pause your subscription? ")]), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.pauseItems,
      "filled": "",
      "dense": "",
      "hide-details": "",
      "item-text": "text",
      "item-value": "value"
    },
    model: {
      value: _vm.pauseMonth,
      callback: function callback($$v) {
        _vm.pauseMonth = $$v;
      },
      expression: "pauseMonth"
    }
  }), _c('p', [_vm._v("One-time offer")])], 1), _c('v-col', {
    staticClass: "pt-4",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "depressed": "",
      "disabled": !_vm.pauseMonth,
      "block": "",
      "color": "primary",
      "loading": _vm.busy
    },
    on: {
      "click": function click($event) {
        return _vm.initiateClaimOffer({
          id: _vm.pauseDetail.id,
          value: "".concat(_vm.pauseMonth)
        });
      }
    }
  }, [_vm._v("Pause subscription")])], 1)], 1)], 1), _c('v-alert', {
    staticClass: "mt-3",
    attrs: {
      "border": "left",
      "color": "info",
      "dense": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font14"
        }, [_vm._v(" This will only reschedule your next payment. Your team will stay active and operate normally. ")])];
      },
      proxy: true
    }], null, false, 2117969644)
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.busy
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.busy
    },
    on: {
      "click": _vm.skipFromPause
    }
  }, [_vm._v("Proceed to cancel")])], 1)] : _vm._e(), _vm.showDowngradeForm ? [_c('v-card-title', [_c('v-spacer'), _c('div', {
    staticClass: "semi-bold titleFont"
  }, [_vm._v("Downgrade your plan?")]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "py-4"
  }, [_c('v-col', {
    staticClass: "text-center text-h6",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v(" Your current plan is "), _c('b', [_vm._v(" " + _vm._s(_vm.subscriptionInfo.name + " ") + " "), _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("(" + _vm._s(_vm.subscriptionInfo.recurring) + ")")])])]), _c('div', {
    staticClass: "text-center mt-4 bodyFont"
  }, [_c('div', [_vm._v(" You can downgrade your team's plan to Starter (Monthly or Annually), effective immediately. ")])]), _c('v-alert', {
    staticClass: "mt-3 text-left",
    attrs: {
      "border": "left",
      "color": "success",
      "dense": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font14"
        }, [_vm._v(" The unused portion of the current billing cycle is applied as a credit for your following payments. ")])];
      },
      proxy: true
    }], null, false, 2608944289)
  }), _c('v-alert', {
    staticClass: "mt-3 text-left",
    attrs: {
      "border": "left",
      "color": "error",
      "dense": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font14"
        }, [_vm._v(" Please review the "), _c('a', {
          attrs: {
            "href": "https://testapp.io/pricing",
            "target": "_blank"
          }
        }, [_vm._v("plan changes")]), _vm._v(" before you downgrade. Your team members, apps, releases, and other features might be affected. ")])];
      },
      proxy: true
    }], null, false, 1275978573)
  })], 1), _c('v-col', {
    staticClass: "pt-0 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('v-select', {
    attrs: {
      "item-value": "id",
      "filled": "",
      "dense": "",
      "id": "plan",
      "hide-details": "",
      "persistent-hint": "",
      "placeholder": "Select a plan",
      "items": _vm.plansList
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.name + " ") + " "), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("(" + _vm._s(item.recurring) + ")")]), _c('span', [_vm._v(" for $" + _vm._s(item.meta.display_price) + "/month ")]), item.recurring === 'annually' ? _c('v-chip', {
          staticClass: "ml-3",
          attrs: {
            "small": ""
          }
        }, [_vm._v("Get 30% discount (~4 months)")]) : _vm._e()], 1)];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.name + " ") + " "), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("(" + _vm._s(item.recurring) + ") ")]), _c('span', [_vm._v(" for $" + _vm._s(item.meta.display_price) + "/month ")]), item.recurring === 'annually' ? _c('v-chip', {
          staticClass: "ml-3",
          attrs: {
            "small": ""
          }
        }, [_vm._v("Get 30% discount (~4 months)")]) : _vm._e()], 1)];
      }
    }], null, false, 4037622268),
    model: {
      value: _vm.selectedPlanId,
      callback: function callback($$v) {
        _vm.selectedPlanId = $$v;
      },
      expression: "selectedPlanId"
    }
  }), _c('p', [_vm._v("There's no undo for this action")])], 1), _c('v-col', {
    staticClass: "pt-4",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "block": "",
      "disabled": !_vm.selectedPlanId,
      "outlined": _vm.$vuetify.theme.dark,
      "loading": _vm.busy,
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        return _vm.initiateClaimOffer({
          id: _vm.downgradeDetails.id,
          value: "".concat(_vm.selectedPlanId)
        });
      }
    }
  }, [_vm._v(" Downgrade ")])], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', [_vm.pauseDetail ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": _vm.busy
    },
    on: {
      "click": _vm.previousFromDowngrade
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-chevron-left')
    }
  }), _vm._v(" Previous ")], 1) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "disabled": _vm.busy,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.busy
    },
    on: {
      "click": _vm.skipFromDowngrade
    }
  }, [_vm._v(" Proceed to cancel ")])], 1)] : _vm._e(), _vm.offerMode ? [_c('v-card-title', [_c('v-spacer'), _c('div', {
    staticClass: "semi-bold titleFont"
  }, [_vm._v("We totally get it.")]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "text-center bodyFont my-4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" We understand the need to be cost conscious right now and want to make TestApp.io affordable to you. ")]), _c('v-col', {
    staticClass: "text-center blue-grey lighten-5 py-5 bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "semi-bold"
  }, [_vm._v("Claim your limited time offer.")]), _c('div', {
    staticClass: "my-3"
  }, [_vm._v(" " + _vm._s(_vm.offerDetail.message) + " ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "depressed": "",
      "outlined": _vm.$vuetify.theme.dark,
      "loading": _vm.busy,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.initiateClaimOffer({
          id: _vm.offerDetail.id
        });
      }
    }
  }, [_vm._v(" Accept this offer ")])], 1)], 1)], 1), _c('v-card-actions', [_vm.pauseDetail || _vm.downgradeDetails ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.busy
    },
    on: {
      "click": _vm.previousFromOffer
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-chevron-left')
    }
  }), _vm._v(" Previous")], 1) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "disabled": _vm.busy,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "disabled": _vm.busy,
      "color": "primary"
    },
    on: {
      "click": _vm.skipToCancelModal
    }
  }, [_vm._v("Decline offer")])], 1)] : _vm._e(), _vm.cancellationSubscriptionNote ? [_c('v-card-title', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Cancel subscription ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "message mt-4"
  }, [_vm._v(" Cancelling the subscription will be implemented "), _vm.subscriptionInfo.cancellation_effective_date ? _c('span', [_vm._v(" on "), _c('b', [_vm._v(_vm._s(_vm.getFormattedDate(_vm.subscriptionInfo.cancellation_effective_date)) + ".")])]) : _c('span', [_vm._v(" by the next billing cycle "), _c('b', [_vm._v(_vm._s(_vm.getFormattedDate(_vm.subscriptionInfo.next_bill_date)))]), _vm._v(". ")]), _c('br'), _c('br'), _vm._v(" The team will be downgraded to the free plan. Members might lose access to certain features when downgrading as well as archiving the over-the-limit releases. "), _c('br'), _c('br'), _vm._v(" Once the cancellation implemented, there's no undo but until then you can still subscribe again to stop the cancellation order before the cancellation date. "), _c('br'), _c('br'), _c('v-alert', {
    staticClass: "mt-3",
    attrs: {
      "border": "left",
      "icon": "info",
      "dense": "",
      "color": "primary",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font14 normal-text"
        }, [_vm._v(" If it is something that we can help with, please do "), _c('span', {
          staticClass: "pointer blue--text text-decoration-underline",
          on: {
            "click": _vm.onContactus
          }
        }, [_vm._v("contact us")]), _vm._v(" or "), _c('span', {
          staticClass: "pointer blue--text text-decoration-underline",
          on: {
            "click": _vm.openSchedule
          }
        }, [_vm._v(" schedule a call ")]), _vm._v(" and we'll gladly help you out! ")])];
      },
      proxy: true
    }], null, false, 433713591)
  })], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "py-3"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "id": "cancelDeclineBtn",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "cancelConfirmBtn",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.openCancelReason
    }
  }, [_vm._v("Got it, cancel it please")])], 1)] : _vm._e(), _vm.cancelReason ? _c('cancel-plan-modal', {
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "success": function success($event) {
        return _vm.$emit('cancel-confirm');
      }
    }
  }) : _vm._e()]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }