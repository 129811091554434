var render = function render(){
  var _vm$info, _vm$info2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-3"
  }, [_c('v-row', {
    staticClass: "darkGrey--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "height": "100%",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "bodyFont mb-3"
  }, [_vm._v("Current billing")]), _vm.subscription.status && _vm.subscription.status === 'cancelled' ? _c('div', {
    staticClass: "titleFont bold-text"
  }, [_vm._v(" $0 ")]) : _c('div', {
    staticClass: "titleFont bold-text"
  }, [_vm.getPromoInfo && _vm.getPromoInfo.source_name ? _c('span', [_vm._v(" $ " + _vm._s(_vm.getPromoInfo.price || "0") + " ")]) : _c('span', [_vm._v(" $ " + _vm._s(_vm.currentSubscription.price || "0") + " ")]), _vm.currentSubscription.recurring ? _c('span', {
    staticClass: "font14"
  }, [_vm._v(" / " + _vm._s(_vm.currentSubscription.recurring))]) : _vm._e()]), _vm.currentSubscription.recurring == 'monthly' && _vm.getTeamTrial && _vm.getTeamTrial.status !== 'active' && _vm.subscription.status === 'active' ? _c('div', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.$emit('switchAnnually');
      }
    }
  }, [_vm._v(" Switch to yearly (save 30% or nearly 4 months free) ")]) : _vm._e(), _vm.getTeamTrial && _vm.getTeamTrial.status && _vm.getTeamTrial.status !== 'upgraded' && _vm.getPromoInfo && !_vm.getPromoInfo.source_name ? _c('div', {
    staticClass: "bodyFont",
    class: _vm.getTeamTrial.status == 'active' ? 'success--text' : 'error--text'
  }, [_vm._v(" " + _vm._s(_vm.getTeamTrial.status == "active" ? "Active trial" : "") + " ")]) : _vm._e()])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "height": "100%",
      "outlined": ""
    }
  }, [_vm.subscription.status === 'paused' ? _c('span', [_c('span', {
    staticClass: "success--text"
  }, [_vm._v(" Paused ")])]) : _vm._e(), _vm.subscription.status === 'suspended' ? _c('span', [_c('span', {
    staticClass: "error--text"
  }, [_vm._v(" Suspended ")])]) : _vm._e(), _c('div', {
    staticClass: "bodyFont mb-2"
  }, [_vm.getTeamTrial && _vm.getTeamTrial.status == 'active' ? _c('div', [_vm._v(" Trial end date ")]) : _vm._e(), _vm.subscription.status && ['active', 'cancelled'].includes(_vm.subscription.status) ? _c('div', [_vm._v(" Next payment due ")]) : _vm._e(), _vm.subscription.status && _vm.subscription.status == 'paused' ? _c('div', [_vm._v(" " + _vm._s(_vm.getFormattedDate(_vm.subscription.paused_at)) + " ")]) : _vm._e(), _vm.subscription.status === 'suspended' ? _c('div', {
    staticClass: "bodyFont regular-font mt-4"
  }, [_vm._v(" Temporarily suspended due to many failed renewal attempts, please update your billing details to resume it. "), _c('br'), _c('br'), _vm._v(" If you have any questions, feel free to "), _c('span', {
    staticClass: "primary--text cursor-pointer text-decoration-underline",
    on: {
      "click": _vm.onContactus
    }
  }, [_vm._v("contact us")]), _vm._v(". ")]) : _vm._e(), !_vm.subscription.status && _vm.getTeamTrial && _vm.getTeamTrial.status != 'active' ? _c('div', [_vm._v(" Next payment due ")]) : _vm._e(), _vm.subscription.status && _vm.subscription.status === 'cancel' ? _c('div', [_vm._v(" Cancellation effective date ")]) : _vm._e()]), _vm.subscription.status === 'paused' ? _c('div', {
    staticClass: "titleFont mb-2 bold-text"
  }, [_vm.currentSubscription.resume_at ? _c('div', {
    staticClass: "bodyFont regular-font mt-4"
  }, [_vm._v(" Resume at " + _vm._s(_vm.getFormattedDate(_vm.currentSubscription.resume_at)) + " ")]) : [_vm.showOldBillingDate && _vm.currentSubscription.old_next_bill_date ? _c('div', {
    staticClass: "font11 text-decoration-line-through"
  }, [_vm._v(" " + _vm._s(_vm.getFormattedDate(_vm.currentSubscription.old_next_bill_date) || "-") + " ")]) : _vm._e(), _vm.currentSubscription.next_bill_date ? _c('div', [_vm._v(" " + _vm._s(_vm.getFormattedDate(_vm.currentSubscription.next_bill_date) || "-") + " ")]) : _c('div', {
    staticClass: "cursor-pointer cardTitleFont text-decoration-underline primary--text",
    on: {
      "click": _vm.onContactus
    }
  }, [_vm._v(" Contact us ")])]], 2) : _vm._e(), _vm.getTeamTrial && _vm.getTeamTrial.status !== 'active' && _vm.currentSubscription.status == 'active' && _vm.getPromoInfo && !_vm.getPromoInfo.source_name ? _c('div', {
    staticClass: "titleFont mb-2 bold-text"
  }, [_vm.currentSubscription.next_bill_date ? _c('div', [_vm._v(" " + _vm._s(_vm.getFormattedDate(_vm.currentSubscription.next_bill_date) || "-") + " ")]) : _c('div', {
    staticClass: "cursor-pointer cardTitleFont text-decoration-underline primary--text",
    on: {
      "click": _vm.onContactus
    }
  }, [_vm._v(" Contact us ")])]) : _vm._e(), !_vm.subscription.status && _vm.getTeamTrial && _vm.getTeamTrial.status !== 'active' ? _c('div', {
    staticClass: "titleFont mb-2 bold-text"
  }, [_vm._v(" - ")]) : _vm._e(), _vm.getPromoInfo && _vm.getPromoInfo.source_name ? _c('div', {
    staticClass: "titleFont mb-2 bold-text"
  }, [_vm._v(" - ")]) : _vm._e(), _vm.subscription.status && _vm.subscription.status == 'cancelled' ? _c('div', {
    staticClass: "titleFont mb-2 bold-text"
  }, [_vm._v(" - ")]) : _vm._e(), _vm.getTeamTrial && _vm.getTeamTrial.status !== 'active' && _vm.currentSubscription.status == 'cancel' && _vm.getPromoInfo && !_vm.getPromoInfo.source_name ? _c('div', {
    staticClass: "titleFont mb-2 bold-text"
  }, [_vm._v(" " + _vm._s(_vm.currentSubscription.cancellation_effective_date ? _vm.getFormattedDate(_vm.currentSubscription.cancellation_effective_date) : "-") + " ")]) : _vm._e(), _vm.getTeamTrial && _vm.getTeamTrial.status === 'active' ? _c('div', {
    staticClass: "titleFont bold-text"
  }, [_vm._v(" " + _vm._s(_vm.getFormattedDate(_vm.getTeamTrial.end_at) || "-") + " ")]) : _vm._e(), _vm.getTeamTrial && _vm.getTeamTrial.status === 'active' && _vm.getPromoInfo && !_vm.getPromoInfo.source_name ? _c('div', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.openIntercomChat(_vm.contactMessage);
      }
    }
  }, [_vm._v(" Contact us to extend trial ")]) : _vm._e()])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "height": "100%",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "bodyFont mb-3"
  }, [_vm._v("Payment information")]), ['active', 'suspended', 'past_due', 'paused'].includes(_vm.subscription.status) && _vm.getPromoInfo && !_vm.getPromoInfo.source_name ? _c('div', [_vm.subscription.urls && _vm.subscription.urls.update ? _c('div', {
    staticClass: "primary--text cursor-pointer text-decoration-underline",
    on: {
      "click": _vm.openUpdateUrl
    }
  }, [_vm._v(" Update billing info ")]) : _vm._e(), _vm.subscription.status !== 'paused' && _vm.subscription.urls && _vm.subscription.urls.cancel ? _c('div', {
    staticClass: "primary--text text-decoration-underline cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancelPlan');
      }
    }
  }, [_vm._v(" Cancel plan ")]) : _vm.subscription.status === 'paused' ? _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "color": "primary"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticClass: "text-muted cursor-pointer"
        }, on), [_vm._v(" Cancel plan ")])];
      }
    }], null, false, 1887211901)
  }, [_c('div', [_vm._v("Subscription is currently paused")])]) : _vm._e()], 1) : _c('div', {
    staticClass: "titleFont bold-text"
  }, [_vm._v("-")])])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.subscription.message ? _c('v-card', {
    staticClass: "pa-4 mb-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.subscription.message)
    }
  })]) : _vm._e(), _vm.subscription.status === 'cancel' ? _c('v-card', {
    staticClass: "px-4 py-4 mb-3",
    attrs: {
      "outlined": ""
    }
  }, [_vm.subscription.status === 'cancel' ? _c('div', {
    staticClass: "bodyFont"
  }, [_vm._v(" We are sorry to see you leave 😔 "), _c('br'), _c('br'), _vm._v(" As you requested, your "), _c('b', [_vm._v(_vm._s(_vm.subscription.name) + " (" + _vm._s(_vm.subscription.recurring) + ")")]), _vm._v(" subscription is scheduled for cancellation "), _vm.subscription.cancellation_effective_date ? _c('span', [_vm._v(" on "), _c('b', [_vm._v(_vm._s(_vm.getFormattedDate(_vm.subscription.cancellation_effective_date)) + ".")]), _c('br'), _c('br'), _vm._v(" You can resubscribe at any time, and we hope you decide to come back soon. "), _c('br'), _c('br'), _vm._v(" Thank you for your support! ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "subListFont"
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-water-circle ")]), _vm._v(" Current Usage ")], 1), _vm.subscription.custom ? _c('div', {
    staticClass: "muted font14"
  }, [_c('v-icon', {
    attrs: {
      "color": "success",
      "small": ""
    }
  }, [_vm._v("mdi-star")]), _vm._v(" This team is on a custom plan and it's better than the current one "), _c('VueTooltip', {
    attrs: {
      "v-html": 'As a thank you for being an early joiner, you will always stay on the best plan! <br /> <br /> If your team decides to upgrade, please contact us for a custom discount 💪',
      "color": "primary",
      "icon-color": "grey",
      "text-class": "white--text"
    }
  })], 1) : _vm._e()]), _c('v-spacer')], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "bodyFont",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_vm._v("Team members")]), _c('v-col', {
    staticClass: "mx-2 bodyFont"
  }, [_vm._v(" " + _vm._s(_vm.info ? _vm.info.total_members || "0" : "0") + " / " + _vm._s(_vm.get(_vm.currentPlanData, "limits.members", "0") == 0 ? "∞" : _vm.get(_vm.currentPlanData, "limits.members", "∞")) + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "bodyFont",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_vm._v("Apps")]), _c('v-col', {
    staticClass: "mx-2 bodyFont"
  }, [_vm._v(" " + _vm._s(_vm.info ? _vm.info.total_apps || "0" : "0") + " / " + _vm._s(_vm.get(_vm.currentPlanData, "limits.apps", "0") == 0 ? "∞" : _vm.get(_vm.currentPlanData, "limits.apps", "∞")) + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "bodyFont",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_vm._v("Storage")]), _c('v-col', {
    staticClass: "bodyFont"
  }, [_vm._v(" " + _vm._s(((_vm$info = _vm.info) === null || _vm$info === void 0 ? void 0 : _vm$info.storage) >= 0 ? _vm.formatBytes(((_vm$info2 = _vm.info) === null || _vm$info2 === void 0 ? void 0 : _vm$info2.storage) || 0) : "0") + " / " + _vm._s(_vm.formatBytes(_vm.get(_vm.plans, "info.plan_usage.storage", 0))) + " ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.confirmationModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "mt-4 bold-text font-size-h3",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Switch to " + _vm._s(_vm.subscription.name) + " "), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("(Annually)")])]), _c('v-col', {
          staticClass: "bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "mb-4"
        }, [_vm._v(" Great choice! Reduce payment failures and lock the current discount for a year 💪 ")]), _c('div', [_vm._v(" Team: "), _c('b', [_vm._v(_vm._s(_vm.getTeamName))])])]), _c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-alert', {
          attrs: {
            "color": "success",
            "outlined": ""
          }
        }, [_c('span', {
          staticClass: "body-1"
        }, [_vm._v(" One-click away from saving "), _c('b', [_vm._v("30%")]), _vm._v(" or nearly "), _c('b', [_vm._v("4 months")]), _vm._v(" free 🎉 ")])]), _c('v-alert', {
          attrs: {
            "color": "info",
            "outlined": ""
          }
        }, [_c('span', {
          staticClass: "body-1"
        }, [_vm._v(" We will apply the remaining balance to your new billing ")])])], 1), _c('v-col', {
          staticClass: "text-right pb-0",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none mx-2",
          attrs: {
            "disabled": _vm.busy,
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.confirmationModal = false;
            }
          }
        }, [_vm._v("Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "switchToAnnuallyBtn",
            "loading": _vm.busy,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('change-plan');
            }
          }
        }, [_vm._v("Switch 🎉 ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.confirmationModal,
      callback: function callback($$v) {
        _vm.confirmationModal = $$v;
      },
      expression: "confirmationModal"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }